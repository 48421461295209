import React from "react";
import QRCode from "react-qr-code";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function Qr() {
  const navigate = useNavigate();

  return (
    <div className=" h-screen flex flex-col space-y-10 items-center justify-center">
      <button
        onClick={() => navigate(-1)}
        className="  p-2 md:ml-10 rounded-full w-full"
      >
        <BiArrowBack className=" text-2xl" />
      </button>
      <div className=" w-48 h-48 ">
        <QRCode value="hey" />
      </div>
      <div className=" text-center   text-lg  text-blue-500 pt-8">
        Chat admin for personal authentication code.
      </div>
    </div>
  );
}

export default Qr;
