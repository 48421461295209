import React from "react";

function Navbar() {
  return (
    <div className=" w-full  overflow-x-hidden shadow-md py-4 pl-4">
      <div className=" font-semibold text-blue-500 text-lg"></div>
    </div>
  );
}

export default Navbar;
