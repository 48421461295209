import "./App.css";
import Login from "./Login";
import Qr from "./Qr";
import Navbar from "./Navbar";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="w-screen  overflow-x-hidden">
      <Navbar />
      <div className="w-screen  overflow-x-hidden">
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/qr" element={<Qr />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
